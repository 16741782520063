body {
    background-color: #0f0f0f !important;
	/* background-color: #666 !important; */
	background: url(../src/assets/images/Banner_New_2.png) !important;
	background-repeat: no-repeat !important;
	background-size: cover !important;
	background-attachment: fixed !important;
	background-position: center !important;
	
	background-blend-mode: overlay !important;
	overflow-x: hidden !important;
}

.content-box {
    background-color: #0f0f0f;
    border-radius: 6px;
	padding: 1rem 2rem;
}

.disabled-nft {
	filter: grayscale(1);
}

.wallet-adapter-dropdown .wallet-adapter-button .wallet-adapter-button-start-icon {
	display: none !important;
}

.wallet-adapter-button {
    border: 2px solid black !important;
    color: white !important;
	border-radius: 6px !important;
 }

 .wallet-adapter-button-trigger {
	 background: black !important;
}

.wallet-button,
.wallet-adapter-button-trigger {
	background: #e0b739 !important;
	color: black !important;
}

.inactive-choice-list {
	opacity: 0.8;
}

.close-img {
	position: absolute;
	right: 16px;
	top: 16px;
}

.close-img:hover {
	cursor: pointer;
}

.void-img:hover {
	cursor: pointer;
}

.trump-container img {
	/* -webkit-filter: drop-shadow(1px 1px 0 white) drop-shadow(-1px -1px 0 white);
	filter: drop-shadow(1px 1px 0 white) drop-shadow(-1px -1px 0 white); */
}

@media screen and (max-width: 600px) {
	.close-img {
		position: absolute;
		right: 8px;
		top: 8px;
	}

	.close-img img {
		width: 30px;
	}
}